import { MantineProvider } from "@mantine/core";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import { json, Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import "./global.css";

import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/spotlight/styles.css";

import { useMemo } from "react";
import "./tailwind.css";

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

const initializeSentry = (dsn: string | undefined) => {
  Sentry.init({
    dsn: dsn,
  });
};

export async function loader({ params, request }: LoaderFunctionArgs) {
  return json({
    sentryDsn: process.env.SENTRY_DSN,
  });
}

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://fonts.googleapis.com/css2?family=Cabin&family=Raleway:wght@200;500;700;800&display=swap"
          rel="stylesheet"
        ></link>
        <Meta />
        <Links />
      </head>
      <body>
        <MantineProvider>
          <div id="rootContainer">{children}</div>
        </MantineProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  const { sentryDsn } = useLoaderData<typeof loader>();

  useMemo(() => {
    initializeSentry(sentryDsn);
  }, [sentryDsn]);

  return <Outlet />;
}
